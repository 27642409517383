<template lang="pug">
  .user-menu(ref="menu")
    AppDropdown(
      close-on-select
      valueKey="dataCy"
      :items="menuItems"
      :value="null"
      :placeholder="currentUser.account_id"
      @select="clickMenuLink"
    )
</template>

<script>
  // mixins
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { api, bus } from "@/config"
  import { forEach } from "lodash-es"
  import { clearAuthCookies } from "@/helpers/auth"
  import { mapState, mapMutations } from "vuex"

  export default {
    mixins: [withConfirmation],

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    computed: {
      ...mapState(["currentUser", "signOutConfirmations", "registeredModuleNames"]),

      useConfirm() {
        return this.signOutConfirmations[this.$route.meta.title]
      },

      menuItems() {
        return [
          { dataCy: "edit-account", name: this.$t("header.title.edit_profile") },
          { dataCy: "sign-out", name: this.$t("header.title.sign_out") }
        ]
      }
    },

    methods: {
      ...mapMutations(["SET_CURRENT_USER", "RESET_STATE"]),

      clickMenuLink({ dataCy }) {
        switch (dataCy) {
          case "edit-account":
            if (this.$route.name !== "EditOwnAccount") {
              this.$router.push({ name: "EditOwnAccount" })
            }
            break
          case "sign-out":
            return this.signOut()
        }
      },

      signOut() {
        this.$conditionalConfirm({
          useConfirm: this.useConfirm,
          handler: () => {
            api
              .delete("auth/sign_out")
              .finally(() => {
                bus.emit("sign-out")
                this.SET_CURRENT_USER()
                this.resetState()
                clearAuthCookies()
                window.location.replace("/sign-in")
              })
              .catch(() => {})
          }
        })
      },

      resetState() {
        // reset state for every registered modules
        forEach(this.registeredModuleNames, name => bus.emit(`reset-${name}-state`))

        // reset common state
        this.RESET_STATE()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .user-menu
    min-width: 155px

    .app-select
      width: 100%

      ::v-deep
        .app-select-label
          opacity: 1
          color: $default-purple
          font-weight: 500

        .dropdown-toggle
          border: none
</style>
